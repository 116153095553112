<template>
	<v-list-tile :value="isSelected" @click="selectTreeStructure()">
		<v-list-tile-action>
			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<v-checkbox color="primary" :input-value="value.has_access" v-on="on" @click.stop="onCheckboxClick" />
				</template>
				<span v-if="value.has_access" v-t="'ecm-catalog.remove_ecm_from_list'" />
				<span v-else v-t="'ecm-catalog.add_ecm_to_list'" />
			</v-tooltip>
		</v-list-tile-action>
		<v-list-tile-content>
			<v-list-tile-title v-text="value.name" />
			<v-list-tile-sub-title>{{ $t('ecm-catalog.number_of_folders', { count: value.number_of_folders }) }}</v-list-tile-sub-title>
		</v-list-tile-content>
	</v-list-tile>
</template>

<script>
import ECMCatalogModuleGuard from '@/mixins/ModulesGuards/ECMCatalog/ECMCatalogModuleGuard'

export default {
	name: 'ECMCatalogListItem',
	mixins: [ECMCatalogModuleGuard],
	props: {
		isSelected: {
			required: false,
			type: Boolean,
			default: false
		},
		value: {
			required: true,
			type: Object
		}
	},
	methods: {
		onCheckboxClick: function () {
			if (this.value.has_access) {
				this.service.removeTreeStructureFromList(this.value)
			} else {
				this.service.addTreeStructureToList(this.value)
			}
		},
		selectTreeStructure: function () {
			this.eventBus.emit(this.events.TREE_STRUCTURE_SELECTED, this.value)
		}
	}
}
</script>
